import React, { useState, useEffect } from 'react';
import Post from '../components/Post';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

// Components
import SignUp from './../components/SignUp';
import AddPost from './../components/AddPost';

// Styles
import * as styles from './HomepageStyles';

// Contexts
import { useData } from '../contexts/data';
import { useUser } from '../contexts/users';

const Homepage = () => {
  const { posts, getPosts } = useData();
  const { user, getUser, isLoggedIn } = useUser();

  const [postsList, setPostsList] = useState<SocialPost[]>([]);

  const [showCreatePost, setShowCreatePost] = useState(false);

  useEffect(() => {
    getUser();
    getPosts();
  }, []);

  useEffect(() => {
    // Sort posts by date (newest first)
    setPostsList(posts.sort((a, b) => b.timestamp - a.timestamp));
  }, [posts]);
  
  return <>
    {!isLoggedIn && <SignUp />}
    {showCreatePost && <AddPost closeCallback={() => setShowCreatePost(false)} />}


    <styles.Feed id="feed">
      <styles.FeedHeader>
        <styles.FeedHeaderTitle onClick={() => getPosts()}>
          🔥 Social Network
        </styles.FeedHeaderTitle>
        <styles.FeedHeaderRight>
          { isLoggedIn && <p>Logged in as: <strong>{user.username}</strong></p> }
          <styles.FeedHeaderButton onClick={() => setShowCreatePost(true)}>
            <FontAwesomeIcon icon={faPlus} /> Add a new post
          </styles.FeedHeaderButton>
        </styles.FeedHeaderRight>
      </styles.FeedHeader>

      { postsList?.map(post => <Post data={post} />) }
      <styles.AddPostButton onClick={() => setShowCreatePost(true)}>
        <FontAwesomeIcon icon={faPlus} />
      </styles.AddPostButton>
    </styles.Feed>

  </>
}

export default Homepage;
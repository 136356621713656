import React, { useState, useEffect, useContext } from 'react';

import { useUser } from './users'; 

interface DataContext {
  posts: SocialPost[],
  getPosts: () => void,
  createPost: (title: string, content: string, color?: number) => Promise<SocialPost|boolean>,
  votePost: (postId: string, vote: 'downvote'|'upvote'|'unset') => Promise<SocialPost|boolean>,
  reactPost: (postId: string, reaction: string, action: 'add'|'remove') => Promise<SocialPost|boolean>,
}

const DataContext = React.createContext<DataContext>({} as DataContext);

export const useData = () => useContext(DataContext);

export const DataProvider: React.FC = ({ children }) => {
  const { token } = useUser();
  const [posts, setPosts] = useState<SocialPost[]>([]);
  
  const getPosts = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`);
    const posts = await response.json();
    setPosts(posts);
  }

  const createPost = async (title: string, content: string, color?: number) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`, {
      method: 'POST',
      body: JSON.stringify({ title, content, color }),
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    if (response.status === 200) {
      // Add post to state
      let post = await response.json();
      // Always will be latest post so can add to beginning
      setPosts([post, ...posts]);
      return post;
    }

    return false;
  }

  const votePost = async (postId: string, vote: 'downvote'|'upvote'|'unset') => {
    // vote is true for upvote, false for downvote
    const response = await fetch(`${process.env.REACT_APP_API_URL}/post/${postId}/vote`, {
      method: 'POST',
      body: JSON.stringify({ vote }),
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    if (response.status === 200) {
      // Update state
      const updatedPost = await response.json();
      const newPosts = posts.map(p => p.id === updatedPost.id ? updatedPost : p);
      setPosts(newPosts);
      return updatedPost;
    }

    return false;
  }
  
  const reactPost = async (postId: string, reaction: string, action: 'add'|'remove') => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/post/${postId}/react`, {
      method: 'POST',
      body: JSON.stringify({ reaction, action }),
      headers: {
        'Authorization': 'Bearer ' + token
      }
    });
    if (response.status === 200) {
      // Update state
      const updatedPost = await response.json();
      const newPosts = posts.map(p => p.id === updatedPost.id ? updatedPost : p);
      setPosts(newPosts);
      return updatedPost;
    }
    return false;
  }

  useEffect(() => {
    getPosts();
  }, []);

  return(
    <DataContext.Provider
      value={{
        posts: posts,
        getPosts: getPosts,
        createPost: createPost,
        votePost: votePost,
        reactPost: reactPost
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataContext;
import styled from 'styled-components';
import { lighten, desaturate } from 'polished';

interface PostStylesProps {
  accent: number
}

export const Post = styled.div<PostStylesProps>`
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid ${props => Object.keys(props.theme.colors.accents).includes(String(props.accent)) ? props.theme.colors.accents[props.accent] : props.theme.colors.primary};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  padding: 1rem;

  .username {
    font-size: 0.9rem;
    font-weight: 700;
    color: #757575;
    font-family: ${props => props.theme.fonts.heading};

    em {
      font-style: normal;
      color: ${props => Object.keys(props.theme.colors.accents).includes(String(props.accent)) ? props.theme.colors.accents[props.accent] : props.theme.colors.primary};
    }
  }

  .title {
    font-size: 1.3rem;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.heading};
  }

  .content {
    white-space: pre-line;
  }

  .interactions {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;

    .ratings {
      p {
        display: inline-block; 
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
      }

      &.loading {
        p {
          cursor: default;
        }
        filter: grayscale(100%);

      }
    }

    .add {
      font-weight: 400;
      font-size: 0.9rem;
      height: fit-content;
      padding: 0.1rem 0.3rem;
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }
    }

    > * {
      color: ${props => Object.keys(props.theme.colors.accents).includes(String(props.accent)) ? props.theme.colors.accents[props.accent] : props.theme.colors.primary};
      border: 1px solid ${props => Object.keys(props.theme.colors.accents).includes(String(props.accent)) ? props.theme.colors.accents[props.accent] : props.theme.colors.primary};
      border-radius: 32px;
      padding: 0.1rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

`;

interface RatingProps {
  active: boolean
}

export const Rating = styled.p<RatingProps>`
  color: #666;
  cursor: pointer;
  ${props => props.active && `
    color: inherit;
  `}
`;

export const EmojiWrapper = styled.div`
  position: absolute;
  z-index: 5;
`;

interface ReactionProps {
  active: boolean
  accent: number
}

export const Reaction = styled.p<ReactionProps>`
  cursor: pointer;
  ${props => props.active && `
    background-color: ${
      desaturate(0.3,lighten(0.3,
        props.theme.colors.accents[props.accent]
      ))
    };
  `}

  span {
    margin-left: 5px;
    color: #000;
  }
`; 
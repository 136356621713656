import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';

import { useUser } from './../contexts/users';
import { useData } from './../contexts/data';

import * as styles from './PostStyles';

interface PostProps {
  data: SocialPost
} 

const Post: React.FC<PostProps> = ({data, ...rest}) => {
  const { user } = useUser();
  const { votePost, reactPost } = useData();

  const [voteLoading, setVoteLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emoji, setEmoji] = useState('');

  const userInList = (list: string[]): boolean => {
    if (list.length === 0) return false;
    return list.includes(user.id);
  }

  interface StructuredReactionData {[key: string]: {
    count: number,
    users: string[]
  }} 

  const restructureReactions = (reactions: {reaction: string, userId: string}[]): StructuredReactionData  => {
    let structured = {} as StructuredReactionData;

    reactions.forEach(reaction => {
      if (!structured[reaction.reaction]) {
        structured[reaction.reaction] = {
          count: 0,
          users: []
        }
      }
      structured[reaction.reaction].count = (structured[reaction.reaction].count || 0) + 1;
      structured[reaction.reaction].users = [...(structured[reaction.reaction].users || []), reaction.userId];
    });

    return structured;
  }

  const changeVote = async (id: string, vote: 'unset'|'upvote'|'downvote') => {
    if (voteLoading) return;
    
    setVoteLoading(true);
    await votePost(id, vote);
    setVoteLoading(false);
  }

  const onEmojiClick = (id: string, chosenEmoji: IEmojiData) => {
    // Extract emoji
    setEmoji(String(chosenEmoji.emoji));
    setShowEmojiPicker(false);
    
    // Send emoji to server
    reactPost(id, chosenEmoji.emoji, 'add');
  };

  const ref: React.RefObject<HTMLDivElement> = React.createRef();
  
  const handleClickOutside = (event: MouseEvent) => {
    if (ref && ref !== null) {
      const cur = ref.current;
      if (cur && !cur.contains(event.target as Node)) {
        // close all dropdowns
        setShowEmojiPicker(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return <>
    <styles.Post accent={data.color}>
      <p className="username"><em>{data.username}</em> | {new Date(data.timestamp).toUTCString()}</p>
      <p className="title">{data.title}</p>
      <p className="content">{data.content}</p>
      <div className="interactions">
        <div className={`ratings ${voteLoading && 'loading'}`}>
          <styles.Rating
            onClick={ () => changeVote(data.id, userInList(data.ratings.upvotes) ? 'unset': 'upvote') }
            active={userInList(data.ratings.upvotes)}
          >
            <FontAwesomeIcon icon={faArrowAltCircleUp} /> {data.ratings.upvotes.length}
          </styles.Rating>
          <styles.Rating
            onClick={ () => changeVote(data.id, userInList(data.ratings.downvotes) ? 'unset': 'downvote') }
            active={userInList(data.ratings.downvotes)}
          >
            <FontAwesomeIcon icon={faArrowAltCircleDown} /> {data.ratings.downvotes.length}
          </styles.Rating>
        </div>
        {Object.entries(restructureReactions(data.reactions)).map(([emoji, countData], index) => 
          <styles.Reaction
            accent={data.color}
            active={countData.users.includes(user.id)}
            onClick={ () => reactPost(data.id, emoji, (countData.users.includes(user.id) ? 'remove' : 'add')) }
            key={index}>
              {emoji} {countData.count > 1 && <span>{countData.count}</span>}
          </styles.Reaction>
        )}
        <p className="add" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>+</p>
      </div>
      <styles.EmojiWrapper ref={ref}>
        {showEmojiPicker && <EmojiPicker
          onEmojiClick={(event, d) => onEmojiClick(data.id, d)}
          disableAutoFocus={true}
          native
        />}
      </styles.EmojiWrapper>
      {/* <div className="comments">
        {data.comments.map((comment, index) => <p key={index}>{comment.username}: {comment.content}</p>)}
      </div> */}
    </styles.Post>
  </>
}

export default Post;
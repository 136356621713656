import React, { useState } from 'react';

// Styles
import * as styles from './ModalStyles';

// Contexts
import { useUser } from './../contexts/users';

const SignUp = () => {
  const { createUser } = useUser();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const sendDetails = async () => {
    setLoading(true);
    setError('');
    
    const success = await createUser(username);

    if (success) {
      setLoading(false);
    } else {
      setError('Error creating user');
      setLoading(false);
    }

  }

  return (
    <styles.SignUpBoxWrapper>
    <styles.SignUpBox>
      <styles.SignUpBoxHeader>
        <h3>Sign Up</h3>
        <h2>You need to sign up to view and post</h2>
      </styles.SignUpBoxHeader>
      <styles.Divider />
      <styles.SignUpBoxBody>
        <p>We need to collect a few pieces of information first so that you can access the site.</p> 
        <p>In the background once you have provided these, we will send your browser a token (JWT) which will be used for authenticating all future posts. This is stored in your browsers Local Storage so will persist on refreshing, but will not persist between devices or browsers.</p> 
        <styles.Divider />
        {error && <p className={'danger'}>{error}</p>}
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <styles.SubmitButton disabled={loading} onClick={() => sendDetails()}>Sign Up</styles.SubmitButton>
      </styles.SignUpBoxBody>
    </styles.SignUpBox>
  </styles.SignUpBoxWrapper>
  );
}

export default SignUp;
import React, { useState, useEffect, useContext } from 'react';

interface UserContext {
  user: User
  getUser: () => void
  isLoggedIn: boolean
  createUser: (username: string) => Promise<boolean>
  token: string
}

const UserContext = React.createContext<UserContext>({} as UserContext);

export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC = ({ children }) => {

  const [token, setToken] = useState('');
  const [user, setUser] = useState({} as User);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const getUser = () => {
    const localToken = localStorage.getItem('userToken') || '';

    if (localToken !== '') {
      setToken(localToken);
      setIsLoggedIn(true);
      let base64Data = localToken.split('.')[1];
      let userData = JSON.parse(Buffer.from(base64Data, 'base64').toString());
      setUser(userData);
    } else {
      setIsLoggedIn(false);
      setUser({} as User);
      setToken('');
    }
    
  }

  const storeUser = () => {
    if (token !== '') {
      localStorage.setItem("userToken", token);
    }
  }

  const createUser = async (username: string) => {
    // Send login request to server then receieve token
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users`,
      {
        method: 'POST',
        body: JSON.stringify({
          username: username
        })
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      let userData = JSON.parse(Buffer.from(data.token.split('.')[1], 'base64').toString());
      setToken(data.token);
      setUser(userData);
      setIsLoggedIn(true);
      return true;
    }

    else return false;
  }

  useEffect(() => {
    storeUser();
  }, [token]);

  useEffect(() => {
    getUser();
  }, []);

  return(
    <UserContext.Provider
      value={{
        user: user,
        getUser: getUser,
        isLoggedIn: isLoggedIn,
        createUser: createUser,
        token: token
      }}
    >
      {children} 
    </UserContext.Provider>
  )
}

export default UserContext;
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Components
import PageTitle from './components/helpers/PageTitle';

// Contexts
import { DataProvider } from './contexts/data';
import { UserProvider } from './contexts/users';

// Pages
import Homepage from './pages/Homepage';
// Import global styles
import { GlobalStyle } from './theme/GlobalStyle';
import { Theme } from './theme/Theme';
const App = () => {
  return <>
    <ThemeProvider theme={Theme}>
      <UserProvider>
        <DataProvider>
          <GlobalStyle />
          <Router>
            <PageTitle title={''} />

            <Routes>
              <Route path="/" element={<Homepage />} />
            </Routes>

          </Router>
        </DataProvider>
      </UserProvider>
    </ThemeProvider>
  </>;
}

export default App;

import { createGlobalStyle } from 'styled-components';

import { Theme } from './Theme';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0px;
  }

  body {
    font-family: ${Theme.fonts.body};
  }

  a {
    color: inherit;
  }

  b {
    font-weight: 700;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-family: ${Theme.fonts.heading};
  }
`;
import styled from 'styled-components';

export const SignUpBoxWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;  
`;

export const SignUpBox = styled.div`
  background-color: white;
  width: 70%;
  max-width: 80rem;
  min-height: 20rem;
  padding: 3rem;
  position: relative;
  border-radius: 0.5rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 0.8rem 0;
`;

export const SignUpBoxHeader = styled.div`
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    color: ${props => props.theme.colors.primary};
  }
`;

export const SignUpBoxBody = styled.div`
  p {
    margin-bottom: 0.4rem;
  }

  p.danger {
    color: #b60909;
    font-weight: 700;
    font-family: ${props => props.theme.fonts.heading};
  }

  & > * {
    display: block;
  }

  input, textarea {
    margin-bottom: 0.4rem;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.primary};
    color: #333;
  }

  textarea {
    min-width: 60%;
    max-width: 100%;
    min-height: 10rem;
    resize: none;
  }
`;

interface SubmitButtonProps {
  disabled?: boolean;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  color: ${props => props.theme.colors.primary};
    background-color: white;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 4px;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    padding: 5px;
    cursor: pointer;

    ${props => props.disabled && `
      opacity: 0.7;
      cursor: not-allowed;
      &:hover {
        
      }
    `}

    &:hover {
      ${props => !props.disabled && `
        color: white;
        background-color: ${props.theme.colors.primary};
      `}
    }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
  color: ${props => props.theme.colors.primary};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.primary};
  }
`;

interface ColorButtonProps {
  color: string
  active: boolean
}

export const ColorPicker = styled.div`
  margin: 1rem 0;
`;

export const ColorButton = styled.div<ColorButtonProps>`
  background: ${props => props.theme.colors.accents[props.color]};
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  cursor: pointer;

  border-radius: 4px;

  ${props => props.active && `
    border-bottom: 3px solid #333;
  `}

  &:last-child {
    margin-right: 0;
  }
  display: inline-block;
`;